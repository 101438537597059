/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

@layer components {
  .text-vertical-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #d1d5db;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #9ca3af;
    border-radius: 100vh;
    /*border: 1px solid #606060;*/
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #6b7280;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #f9fafc;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  height: 100vh;
  margin: 0;
  overflow-x: hidden;
}

.input-without-padding input {
  padding-top: 10px;
  padding-bottom: 10px;
}

#root {
  height: 100%;
}
